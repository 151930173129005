<template>
  <b-card-actions
    ref="cardA"
    title="List"
    action-collapse
    action-refresh
    no-body
    @refresh="doLoadData('cardA')"
  >
    <b-card class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
          <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2" />
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="doTableSort"
          >
            <template #cell(is_active)="data">
              <HeroCheckbox
                id="is-active-display"
                :value="!!data.item.is_active"
                readonly
                disabled
                class="custom-display-checkbox m-auto"
              />
            </template>
            <template #cell(is_media)="data">
              <HeroCheckbox
                id="is-media-display"
                :value="!!data.item.is_media"
                readonly
                disabled
                class="custom-display-checkbox m-auto"
              />
            </template>
            <template #cell(is_channel)="data">
              <HeroCheckbox
                id="is-channel-display"
                :value="!!data.item.is_channel"
                readonly
                disabled
                class="custom-display-checkbox m-auto"
              />
            </template>
            <!-- !! Action Column -->
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none action-button"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal="'product-edit-modal'"
                  @click="doEditData(data.item.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <!--                <b-dropdown-item-->
                <!--                  v-if="$can('Edit', 'Products')"-->
                <!--                  @click="doDeleteData(data.item.id)"-->
                <!--                >-->
                <!--                  <feather-icon-->
                <!--                    icon="Trash2Icon"-->
                <!--                    class="mr-50"-->
                <!--                  />-->
                <!--                  <span>Delete</span>-->
                <!--                </b-dropdown-item>-->
                <!--              </b-dropdown>-->
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>

      <!-- !! Modal -->
      <ProductCreateModal @success="doLoadData" />
      <ProductEditModal :edit-id="editId" @success="doLoadData" />
    </b-card>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import ProductCreateModal from '@/views/heroai/product-offering/products/components/ProductCreateModal.vue'
import ProductEditModal from '@/views/heroai/product-offering/products/components/ProductEditModal.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'

export default {
  components: {
    HeroCheckbox,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    ProductCreateModal,
    ProductEditModal,
  },
  data() {
    return {
      editId: '',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Product Code',
            key: 'product_code',
            sortable: true,
          },
          {
            label: 'Revenue Type Code',
            key: 'revenue_type_code',
            sortable: true,
          },
          {
            label: 'Product Family Name',
            key: 'product_family.name',
            sortable: false,
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
          },
          {
            label: 'Ad Type',
            key: 'ad_type',
            sortable: true,
          },
          {
            label: 'Campaign Name Convention',
            key: 'campaign_name_convention',
            sortable: true,
          },
          {
            label: 'Media Plan Convention',
            key: 'media_plan_convention',
            sortable: true,
          },
          {
            label: 'Active',
            key: 'is_active',
          },
          {
            label: 'Media',
            key: 'is_media',
          },
          {
            label: 'Channel',
            key: 'is_channel',
          },
          {
            label: 'Action',
            key: 'action',
            stickyColumn: false,
          },
        ],
      },
    }
  },
  async mounted() {
    await this.$store.dispatch('KpiUnitOptions/fetchOptions')
    await this.$store.dispatch('MetricUnitOptions/fetchOptions')
    await this.$store.dispatch('ProductFamilyOptions/fetchOptions')
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.cardA.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `products?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axios.get(url, axiosConfig)

        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = response.data.data.data
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        this.tableConfig.totalRows = 0
      }

      this.$refs.cardA.showLoading = false
    },

    doEditData(id) {
      this.editId = id
    },

    // async doDeleteData(id) {
    //   let alertResult
    //
    //   const token = store.getters['heroAiAuthentications/sanctumToken']
    //   const axiosConfig = AxiosConfig.getJsonWithToken(token)
    //
    //   alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete this product information?' })
    //
    //   if (alertResult.value) {
    //     this.$refs.cardA.showLoading = true
    //
    //     try {
    //       const response = await axios.delete(`products/${id}`, axiosConfig)
    //
    //       alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })
    //
    //       await this.doLoadData()
    //     } catch (error) {
    //       await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
    //     }
    //
    //     this.$refs.cardA.showLoading = false
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
.custom-display-checkbox {
  ::v-deep .custom-checkbox {
    padding: 0;
    .custom-control-input {
      left: 50%;
      transform: translateX(-50%);
    }
    .custom-control-label {
      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
