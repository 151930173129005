<template>
  <b-modal
    id="product-edit-modal"
    ref="product-edit-modal"
    title="Edit"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>

        <b-row>
          <!-- !! Name -->
          <b-col cols="12">
            <HeroInputText
              id="name"
              v-model="product.name"
              label="Name"
              column="4"
              :readonly="true"
            />
          </b-col>

          <!-- !! Product Code -->
          <b-col cols="12">
            <HeroInputText
              id="product-code"
              v-model="product.product_code"
              label="Product Code"
              column="4"
              :readonly="true"
            />
          </b-col>

          <!-- !! Revenue Type Code -->
          <b-col cols="12">
            <HeroInputText
              id="revenue-type-code"
              v-model="product.revenue_type_code"
              label="Revenue Type Code"
              column="4"
              :readonly="true"
            />
          </b-col>

          <!-- !! Product Family -->
          <b-col cols="12">
            <HeroInputText
              id="product-family"
              v-model="product.product_family.name"
              label="Product Family"
              column="4"
              :readonly="true"
            />
          </b-col>

          <!-- !! Description -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules=""
            >
              <HeroInputText
                id="description"
                v-model="product.description"
                label="Description"
                column="4"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Ad Type -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="ad type"
              rules=""
            >
              <HeroInputText
                id="ad-type"
                v-model="product.ad_type"
                label="Ad Type"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Campaign Name Convention -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="campaign name convention"
              rules=""
            >
              <HeroInputText
                id="campaign-name-convention"
                v-model="product.campaign_name_convention"
                label="Campaign Name Convention"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Media Plan Convention -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="media plan convention"
              rules=""
            >
              <HeroInputText
                id="media-plan-convention"
                v-model="product.media_plan_convention"
                label="Media Plan Convention"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! KPI Unit Map Metric -->
        <b-row
          v-for="(map, index) in maps"
          :id="`${map.id}-${index}`"
          :key="`${map.id}-${index}`"
          ref="metricRow"
        >
          <b-col cols="5">
            <validation-provider
              #default="{ errors }"
              name="kpi"
              rules="required"
            >
              <HeroVueSelect
                :id="`columnA-${map.id}`"
                v-model="map.property"
                label="KPI Unit"
                :clearable="false"
                :options="$store.getters['KpiUnitOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="5">
            <validation-provider
              #default="{ errors }"
              name="metric"
              rules="required"
            >
              <HeroVueSelect
                :id="`columnB-${map.id}`"
                v-model="map.value"
                label="Actual Convention Mapping"
                :clearable="false"
                :options="$store.getters['MetricUnitOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <HeroButtonAction class="mt-2" type="button" variant="primary" @click="deleteRow(index)">
              <feather-icon icon="TrashIcon" />
            </HeroButtonAction>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" offset-sm="8" sm="4">
            <HeroButtonAction type="button" variant="primary" @click="addRow">
              Add New Mapping
            </HeroButtonAction>
          </b-col>
        </b-row>

        <b-row>
          <!-- !! Active -->
          <b-col cols="12">
            <HeroCheckbox
              id="is-active"
              v-model="product.is_active"
              label="Is Active"
              column="4"
            />
          </b-col>

          <!-- !! Channel -->
          <b-col cols="12">
            <HeroCheckbox
              id="is-channel"
              v-model="product.is_channel"
              label="Is Channel"
              column="4"
            />
          </b-col>

          <!-- !! Media -->
          <b-col cols="12">
            <HeroCheckbox
              id="is-media"
              v-model="product.is_media"
              label="Is Media"
              column="4"
            />
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              v-if="$can('Edit', 'Products')"
              type="button"
              variant="primary"
              @click="validationForm"
            >
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import AxiosBodyService from '@/services/AxiosBodyService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroVueSelect,
    HeroCheckbox,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: true,

      // Validation
      required,

      product: {
        name: '',
        product_code: '',
        revenue_type_code: '',
        product_family_id: '',
        product_family: {},
        description: '',
        ad_type: '',
        campaign_name_convention: '',
        media_plan_convention: '',
        kpi_unit_map_metric: {},
        is_active: false,
        is_channel: false,
        is_media: false,
      },

      // Repeatable Form
      maps: [],
      mapsNextId: 2,
    }
  },
  methods: {
    async doCloseModal() {
      await this.$refs['product-edit-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const response = await axios.get(`products/${this.editId}`, axiosConfig)
        const product = response.data.data

        this.product.name = product.name || ''
        this.product.product_code = product.product_code || ''
        this.product.revenue_type_code = product.revenue_type_code || ''
        this.product.product_family_id = product.product_family_id || ''
        this.product.product_family = product.product_family || {}
        this.product.description = product.description || ''
        this.product.ad_type = product.ad_type || ''
        this.product.campaign_name_convention = product.campaign_name_convention || ''
        this.product.media_plan_convention = product.media_plan_convention || ''
        this.product.kpi_unit_map_metric = product.kpi_unit_map_metric || {}
        this.product.is_active = product.is_active === 1 || false
        this.product.is_channel = product.is_channel === 1 || false
        this.product.is_media = product.is_media === 1 || false

        this.maps = AxiosBodyService.productUnmapping(this.product.kpi_unit_map_metric)
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        this.product.kpi_unit_map_metric = AxiosBodyService.productMapping(this.maps)
        const body = this.product

        try {
          const response = await axios.patch(`products/${this.editId}`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$emit('success')
            this.$refs['product-edit-modal'].hide()
          }
        } catch (error) {
          this.$emit('fail')
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    addRow() {
      this.maps.push({ id: this.mapsNextId, property: '', value: '' })
      this.mapsNextId += 1
    },

    deleteRow(index) {
      this.maps.splice(index, 1)
    },
  },
}
</script>
